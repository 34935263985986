import { Entity } from '@backstage/catalog-model';
import { ConfigApi } from '@backstage/core-plugin-api';
import { KmxProxyApi, OnrampApi } from '../../onrampCommon';

export const MICROSOFT_GRAPH_USER_PRINCIPAL_NAME_ANNOTATION = 'graph.microsoft.com/user-principal-name';

export type HttpMethod = 'GET' | 'PUT' | 'POST' | 'DELETE' | 'PATCH' | 'HEAD' | 'OPTIONS';

type AdxApiRequestInit = {
    headers?: Record<string, string>;
    method?: HttpMethod;
    body?: any;
    target_uri?: string;
};

export interface AdxConfig {
    carfBaseUrl: string;
    registrarBaseUrl: string;
    stagrBaseUrl: string;
}

let currentUser: Entity | undefined = undefined;

export let adxProxyFetch: AdxApiClient['adxFetch'] = (
    _path: string,
    _init?: AdxApiRequestInit | undefined,
): Promise<Response> => {
    return Promise.reject('adxProxyFetch has not been initialized');
};

export let getAdxConfig: () => AdxConfig = () => {
    throw new Error('getAdxConfig not initialized');
};

export class AdxApiClient {
    static initialize(options: { kmxProxyApi: KmxProxyApi; configApi: ConfigApi; onrampApi: OnrampApi }) {
        adxProxyFetch = async (path: string, init?: AdxApiRequestInit) => {
            const adxOAuthProxyBaseUrl = options.configApi.getString('adxOAuthProxy.baseUrl');
            const retryLimit = options.configApi.getNumber('serviceRetry.lowLimit');
            const retryTimeout = options.configApi.getNumber('serviceRetry.lowTimeout');

            const adxUrl = `${adxOAuthProxyBaseUrl}/api/api_proxy${path}`;

            if (currentUser === undefined) {
                currentUser = await options.onrampApi.getCurrentUser();
            }

            const headers: Record<string, string> = {
                ['from']: (currentUser?.metadata?.annotations ?? {})[MICROSOFT_GRAPH_USER_PRINCIPAL_NAME_ANNOTATION],
                ...(init?.headers ?? {}),
            };

            return await options.kmxProxyApi.performProxiedRequest('adxproxy', {
                url: adxUrl,
                method: init?.method ?? 'GET',
                headers,
                body: init?.body,
            },
            retryLimit,
            retryTimeout);
        };
        getAdxConfig = (): AdxConfig => ({
            carfBaseUrl: options.configApi.getString('adxCarf.baseUrl'),
            registrarBaseUrl: options.configApi.getString('adxRegistrar.baseUrl'),
            stagrBaseUrl: options.configApi.getString('adxStagr.baseUrl'),
        });
    }

    constructor(options: { kmxProxyApi: KmxProxyApi; configApi: ConfigApi; onrampApi: OnrampApi }) {
        AdxApiClient.initialize(options);
    }

    async adxFetch(path: string, init?: AdxApiRequestInit): Promise<Response> {
        return await adxProxyFetch(path, init);
    }

    getAdxConfig(): AdxConfig {
        return getAdxConfig();
    }
}
