import React, { useEffect, useState } from 'react';
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material';

type AzureCostGroupProps = {
    groupLabel: string;
    groupList: string[];
    selectedGroups: string[];
    setSelectedGroups: (groups: string[]) => void;
};

export const AzureCostGroupFilter = ({
    groupLabel,
    groupList,
    selectedGroups,
    setSelectedGroups,
}: AzureCostGroupProps) => {
    const [isSelectAll, setSelectAll] = useState<boolean>(false);
    const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
    const [localSelectedGroups, setLocalSelectedGroups] = useState<string[]>([]);

    useEffect(() => {
        selectedGroups.length === groupList.length ? setSelectAll(true) : setSelectAll(false);
        setLocalSelectedGroups(selectedGroups);
    }, [selectedGroups]);

    useEffect(() => {
        localSelectedGroups.length === groupList.length ? setSelectAll(true) : setSelectAll(false);
    }, [localSelectedGroups]);

    const onSelectGroup = (event: SelectChangeEvent<string[]>) => {
        const valueStr = event.target.value as string[];
        const filteredValueStr = valueStr.filter(x => x !== 'selectAll');
        setLocalSelectedGroups(filteredValueStr);
    };

    const selectAll = (_event: React.ChangeEvent<{ value: string[] | unknown }>) => {
        const selectAllGroups = isSelectAll === false ? groupList : [];
        setSelectAll(!isSelectAll);
        setLocalSelectedGroups(selectAllGroups);
    };

    const isOpened = (_event: React.ChangeEvent<{}>) => {
        setIsSelectOpen(true);
    };

    const isClosed = (_event: React.ChangeEvent<{}>) => {
        setIsSelectOpen(false);
        setSelectedGroups(localSelectedGroups);
    };

    return (
        <FormControl variant="outlined" fullWidth>
            <Select
                multiple
                open={isSelectOpen}
                onOpen={isOpened}
                onClose={isClosed}
                onChange={onSelectGroup}
                label="groupLabel"
                labelId={`label-for-${groupLabel}-select`}
                value={localSelectedGroups}
                renderValue={(selected: any) => `${selected.length} Selected`}
                MenuProps={{ anchorEl: null }}
            >
                <MenuItem value="selectAll" key="sa">
                    <Checkbox
                        checked={isSelectAll}
                        onChange={e => {
                            e.stopPropagation();
                            selectAll(e);
                        }}
                        onClick={e => e.stopPropagation()}
                        color="default"
                    />
                    <ListItemText primary="Select All" />
                </MenuItem>
                {(groupList ?? []).map((group, index) => {
                    return (
                        <MenuItem value={group} key={index}>
                            <Checkbox checked={localSelectedGroups.indexOf(group) !== -1} color="default" />
                            <ListItemText primary={group} />
                        </MenuItem>
                    );
                })}
            </Select>
            <InputLabel id={`label-for-${groupLabel}-select`}>{groupLabel}</InputLabel>
        </FormControl>
    );
};
