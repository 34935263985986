import { createApiRef, ConfigApi, IdentityApi } from '@backstage/core-plugin-api';
import crossFetch from 'cross-fetch';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

declare type FetchApi = {
    fetch: typeof fetch;
};

declare type DiscoveryApi = {
    getBaseUrl(pluginId: string): Promise<string>;
};

export interface KmxProxyApi {
    performProxiedRequest(
        type: ProxyType,
        request: ProxiedRequest,
        retryLimit?: number,
        retryTimeout?: number,
    ): Promise<Response>;
}

type ProxyType =
    | 'apigeeproxy'
    | 'kmxproxy'
    | 'onrampfunctionsproxy'
    | 'apxproxy'
    | 'techdocsfunctionsproxy'
    | 'adxproxy';

type ProxiedRequest = {
    url: string;
    method: string;
    body?: any;
    headers?: Record<string, string>;
};

export class KmxProxyClient implements KmxProxyApi {
    private readonly discoveryApi: DiscoveryApi;
    private readonly identityApi: IdentityApi;
    private readonly fetchApi: FetchApi;
    private readonly configApi: ConfigApi;
    reactPlugin: ReactPlugin;

    constructor(options: {
        discoveryApi: DiscoveryApi;
        identityApi: IdentityApi;
        configApi: ConfigApi;
        fetchApi?: FetchApi;
    }) {
        this.discoveryApi = options.discoveryApi;
        this.identityApi = options.identityApi;
        this.configApi = options.configApi;
        this.fetchApi = options.fetchApi || { fetch: crossFetch };

        const browserHistory = createBrowserHistory();
        const reactPlugin = new ReactPlugin();
        const appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: options.configApi.getString('applicationInsights.iKey'),
                extensions: [reactPlugin],
                extensionConfig: { [reactPlugin.identifier]: { history: browserHistory } },
                disableAjaxTracking: true,
            },
        });
        appInsights.loadAppInsights();

        this.reactPlugin = reactPlugin;
    }

    async performProxiedRequest(
        type: ProxyType,
        request: ProxiedRequest,
        retryLimit?: number,
        retryTimeout?: number,
    ): Promise<Response> {
        const proxyBaseUrl = `${await this.discoveryApi.getBaseUrl(type)}`;
        const proxyUrl = `${proxyBaseUrl}?url=${encodeURIComponent(request.url)}`;

        const retriesLimit = retryLimit ?? this.configApi.getNumber('serviceRetry.defaultLimit');
        const retriesTimeout = retryTimeout ?? this.configApi.getNumber('serviceRetry.defaultTimeout');

        const token = (await this.identityApi.getCredentials()).token;
        const initialHeaders = {
            Authorization: `Bearer ${token}`,
            onramp_token: token ?? '',
        };

        const headers: Record<string, string> = { ...initialHeaders, ...(request.headers ?? {}) };

        let body: BodyInit | null | undefined = undefined;
        if (request.body) {
            // Prevent the body from being double-encoded - only call JSON.stringify if it's
            // not a string
            body = typeof request.body === 'string' ? request.body : JSON.stringify(request.body);

            // Prevent the content-type header from being set twice - only add it if it's not
            // already present in additionalHeaders, and if body is being set.
            if (Object.keys(headers).filter(header => header.toLowerCase() === 'content-type').length === 0) {
                headers['Content-Type'] = 'application/json';
            }
        }

        let currentRetries = 0;
        let response = new Response();
        const userIdent = await (await this.identityApi.getBackstageIdentity()).userEntityRef;

        do {
            response = await this.fetchApi.fetch(proxyUrl, {
                method: request.method,
                headers,
                body,
            });

            if (response.ok) {
                this.captureEvent(proxyUrl, userIdent);
                return response;
            }

            this.captureException(userIdent, response);
            currentRetries++;
            await delay(retriesTimeout);
        } while (currentRetries < retriesLimit);

        return response;
    }

    private captureException(userIdent: string, response: Response) {
        let customProps = {
            identity: userIdent,
            responseStatusCode: response.status,
            responseStatusText: response.statusText,
            requestUrl: response.url,
            eventLocation: 'kmxProxyApi',
            apiRef: 'onramp-proxy',
            rawResponse: JSON.stringify(response),
        };
        let err = new Error('Request failure in onramp-proxy');

        this.reactPlugin.trackException({
            exception: err,
            properties: customProps,
        });
    }

    private captureEvent(proxyUrl: string, userIdent: string) {
        let customProps = {
            action: 'kmxProxyApi',
            subject: proxyUrl,
            identity: userIdent,
        };

        this.reactPlugin.trackEvent({
            name: 'onramp-proxy Request',
            properties: customProps,
        });
    }
}

function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const kmxProxyApiRef = createApiRef<KmxProxyApi>({
    id: 'onramp-proxy',
});

