import React, { useState, useCallback } from 'react';
import { ThemeProvider } from '@mui/material';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { DateTime } from 'luxon';
import { currentTheme } from '../../../theme/OnrampTheme';

type AzureCostDateRangeProps = {
    dateLabel: string;
    dateValue: DateTime;
    updateDateValue: (date: DateTime) => void;
    minDate?: string;
    maxDate?: string;
};

export const AzureCostDateRangeFilter = ({
    dateLabel,
    dateValue,
    updateDateValue,
    minDate,
    maxDate,
}: AzureCostDateRangeProps) => {
    const [isError, setIsError] = useState<boolean>(false);

    const localUpdate = useCallback((dateObj: DateTime) => {
        if (dateObj.isValid) {
            setIsError(false);
            if (dateObj.toFormat('MM-dd-yyyy') !== dateValue.toFormat('MM-dd-yyyy')) {
                updateDateValue(dateObj);
            }
        } else {
            setIsError(true);
        }
    }, []);

    return (
        <ThemeProvider theme={currentTheme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    id={`date-picker-inline-${dateLabel.split(' ').join('-').toLowerCase()}`}
                    label={dateLabel}
                    value={dateValue}
                    error={isError}
                    autoOk={true}
                    onChange={() => undefined}
                    onAccept={(newValue: any) => {
                        const changeDate = DateTime.fromJSDate(newValue as Date);
                        localUpdate(changeDate);
                    }}
                    onBlur={(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        const parseDate = new Date(event.target.value);
                        const localDate = DateTime.fromJSDate(parseDate);
                        localUpdate(localDate);
                    }}
                    onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                        if (event.key === 'Enter') {
                            const textArea = event.target as HTMLTextAreaElement;
                            textArea.blur();
                        }
                    }}
                    inputVariant="outlined"
                    minDate={minDate ? minDate : '01-01-2021'}
                    maxDate={maxDate ? maxDate : new Date()}
                    fullWidth
                    //disableFuture
                />
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    );
};
