import { currentTheme } from './OnrampTheme';
import { ThemeRegistration } from './types';

export const themes: ThemeRegistration[] = [
    {
        id: 'onramp-default',
        title: 'Onramp Default',
        variant: 'light',
        theme: currentTheme,
    },
];
