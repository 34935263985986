import { createApiRef } from '@backstage/core-plugin-api';
import { NameCountDate, TermCount } from '../../onrampUsage/types';
import { ClientAppConfig } from '../types/common';
import {
    AdGroup,
    AdoServiceAccountLookup,
    DevOpsProject,
    GitHubTeam,
    ResourcePreviews,
    Subscription,
    SubscriptionResourceGroups,
    User,
    SpoLookupValue,
    AzureRoleLookupItem,
    AzureTaggingResource,
} from '../types/lookups';
import { ApxApiClient } from './ApxApiClient';
import { ValidForCLAccountCreationResponse } from '../types/valid-cl-account-creation-response';
import { MetadataResponse } from '../redux/slices/adxSlices/adxRequestSlice';

export interface ApxApi {
    fetchAppConfig(): Promise<ClientAppConfig>;
    getUsers(nameFragment: string): Promise<User[]>;
    getGroups(nameFragment: string): Promise<AdGroup[]>;
    getGroupMembers(idFragment: string): Promise<User[]>;
    getSpos(nameFragment: string): Promise<SpoLookupValue[]>;
    getAdGroupsOnly(nameFragment: string): Promise<AdGroup[]>;
    getAzureRoles(subscriptionId: string): Promise<AzureRoleLookupItem[]>;
    searchAzureTags(): Promise<AzureTaggingResource[]>;
    getAadGroupsOnly(nameFragment: string): Promise<AdGroup[]>;
    getSubscriptions(): Promise<Subscription[]>;
    getGithubTeams(): Promise<GitHubTeam[]>;
    getResourceGroups(subscriptionId: string): Promise<SubscriptionResourceGroups>;
    getDevOpsProjects(): Promise<DevOpsProject[]>;
    getAdoGithubServiceConnections(): Promise<AdoServiceAccountLookup[]>;
    getSubscriptionResourcePreviews(name: string): Promise<ResourcePreviews>;
    getRegistrarQueryMetadata(queryType: string): Promise<MetadataResponse>;
    getSpoResourcePreviews(
        projectName: string,
        applicationName: string,
        environment: string,
    ): Promise<ResourcePreviews>;
    getUsageStatistics(): Promise<NameCountDate[]>;
    getUsageStatisticsCount(): Promise<TermCount[]>;
    validForCLAccountCreation(empId: string, empName: string): Promise<ValidForCLAccountCreationResponse>;
}

export class ApxClient implements ApxApi {
    private readonly apxApiClient: ApxApiClient;

    constructor(options: { apxApiClient: ApxApiClient }) {
        this.apxApiClient = options.apxApiClient;
    }

    async fetchAppConfig(): Promise<ClientAppConfig> {
        const response = await this.apxApiClient.fetch('/app-config');
        return await response.json();
    }

    async getUsers(nameFragment: string): Promise<User[]> {
        return await this.apxApiClient
            .fetch(`/api/graph/users?nameFragment=${nameFragment}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            });
    }

    async getGroups(nameFragment: string): Promise<AdGroup[]> {
        return await this.apxApiClient
            .fetch(`/api/graph/groups?nameFragment=${nameFragment}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            });
    }

    async getGroupMembers(idFragment: string): Promise<User[]> {
        return await this.apxApiClient
            .fetch(`/api/graph/group-members?idFragment=${idFragment}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            });
    }

    async getAdGroupsOnly(nameFragment: string): Promise<AdGroup[]> {
        return await this.apxApiClient
            .fetch(`/api/graph/ad-groups?nameFragment=${nameFragment}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            });
    }

    async getAadGroupsOnly(nameFragment: string): Promise<AdGroup[]> {
        return await this.apxApiClient
            .fetch(`/api/graph/aad-groups?nameFragment=${nameFragment}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            });
    }

    async getSpos(nameStartsWith: string): Promise<SpoLookupValue[]> {
        return await this.apxApiClient
            .fetch(`/api/graph/spos?nameStartsWith=${nameStartsWith}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            });
    }
    async getSubscriptions(): Promise<Subscription[]> {
        return await this.apxApiClient
            .fetch('/api/azure/subscriptions', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            });
    }

    async getAzureRoles(subscriptionId: string): Promise<AzureRoleLookupItem[]> {
        return await this.apxApiClient
            .fetch(`/api/azure/roles?subscriptionId=${subscriptionId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            });
    }

    async searchAzureTags(): Promise<AzureTaggingResource[]> {
        return await this.apxApiClient
            .fetch(`/api/azure/search-tags`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            });
    }

    async getGithubTeams(): Promise<GitHubTeam[]> {
        return await this.apxApiClient
            .fetch('/api/github/teams', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            });
    }

    async getResourceGroups(subscriptionId: string): Promise<SubscriptionResourceGroups> {
        return await this.apxApiClient
            .fetch(`/api/azure/resource-groups?subscriptionId=${subscriptionId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            });
    }

    async getDevOpsProjects(): Promise<DevOpsProject[]> {
        return await this.apxApiClient
            .fetch('/api/azuredevops/projects', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            });
    }

    async getAdoGithubServiceConnections(): Promise<AdoServiceAccountLookup[]> {
        return await this.apxApiClient
            .fetch('/api/azuredevops/service-accounts', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            });
    }

    async getSubscriptionResourcePreviews(name: string): Promise<ResourcePreviews> {
        return await this.apxApiClient
            .fetch(`/api/azure/resource-previews/subscriptions?name=${name}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            });
    }

    async getSpoResourcePreviews(
        projectName: string,
        applicationName: string,
        environment: string,
    ): Promise<ResourcePreviews> {
        return await this.apxApiClient
            .fetch(
                `/api/azure/resource-previews/spos?projectName=${projectName}&applicationName=${applicationName}&environment=${environment}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
            )
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            });
    }

    async getUsageStatistics(): Promise<NameCountDate[]> {
        return await this.apxApiClient
            .fetch(`/api/provisioning-requests/stats`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            });
    }

    async getUsageStatisticsCount(): Promise<TermCount[]> {
        return await this.apxApiClient
            .fetch(`/api/provisioning-requests/stats/count`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            });
    }

    async validForCLAccountCreation(empId: string, empName: string): Promise<ValidForCLAccountCreationResponse> {
        return await this.apxApiClient
            .fetch(`/api/azure/valid-for-cl-account-creation?empId=${empId}&empName=${empName}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            });
    }

    async getRegistrarQueryMetadata(queryType: string): Promise<MetadataResponse> {
        return await this.apxApiClient
            .fetch(`/api/azure/registrar-query-metadata?metadata=tags&type=${queryType}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            });
    }
}

export const apxApiRef = createApiRef<ApxApi>({
    id: 'applicationprovisioning',
});
