import { Theme } from '@mui/material';
import { OnrampTheme } from '../../../../../theme';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme, OnrampTheme>((theme: Theme) =>
    createStyles({
        default: {
            margin: theme.spacing(3),
            padding: theme.spacing(3),
            minWidth: '236px',
            borderRadius: '2px',
            height: '36px',
            backgroundColor: 'white',
            color: onrampTheme => onrampTheme.palette.infoText,
            fontWeight: 700,
            '&:hover': {
                color: onrampTheme => onrampTheme.palette.infoText,
            },
        },
        active: {
            backgroundColor: onrampTheme => `${onrampTheme.palette.navigation.background}`,
            color: 'white !important',
            '&:hover': {
                backgroundColor: onrampTheme => `${onrampTheme.palette.navigation.background}`,
            },
        },
        buttonIconWrapper: { position: 'absolute', right: 12, top: 6 },
    }),
);

export { useStyles };
