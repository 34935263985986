import ObservableImpl from 'zen-observable';
import { v4 as uuid } from 'uuid';
import { Shortcut, ShortcutApi } from '@backstage/plugin-shortcuts';
import { UserProfileDataApi } from '../../onrampCommon/apis/UserProfileDataApi';
import { onrampTheme } from '../../../theme';

/**
 * Implementation of the ShortcutApi that uses the StorageApi to store shortcuts.
 */
export class ShortcutsClient implements ShortcutApi {
    private readonly bucketName = 'shortcuts';
    constructor(private readonly userProfileDataApi: UserProfileDataApi) {
        this.notify();
    }

    shortcut$() {
        return this.observable;
    }

    async add(shortcut: Omit<Shortcut, 'id'>) {
        const shortcuts = await this.get();
        shortcuts.push({ ...shortcut, id: uuid() });
        await this.userProfileDataApi.set(this.bucketName, 'items', shortcuts);
        // await this.storageApi.set('items', shortcuts);
        this.notify();
    }

    get(): Shortcut[] {
        return this.shortcutsSnapshot;
    }

    async remove(id: string) {
        let shortcuts = await this.get();
        shortcuts = shortcuts.filter(s => s.id !== id);
        await this.userProfileDataApi.set(this.bucketName, 'items', shortcuts);
        // await this.storageApi.set('items', shortcuts);
        this.notify();
    }

    async update(shortcut: Shortcut) {
        let shortcuts = await this.get();
        shortcuts = shortcuts.filter(s => s.id !== shortcut.id);
        shortcuts.push(shortcut);

        await this.userProfileDataApi.set(this.bucketName, 'items', shortcuts);
        // await this.storageApi.set('items', shortcuts);
        this.notify();
    }

    getColor(url: string) {
        const type = url.split('/')[1];

        switch (type) {
            case 'docs':
                return onrampTheme.palette.shortcuts.documentation;
            case 'catalog':
                return onrampTheme.palette.shortcuts.catalog;
            default:
                return onrampTheme?.palette.shortcuts.default;
        }
    }

    private subscribers = new Set<ZenObservable.SubscriptionObserver<Shortcut[]>>();

    private readonly observable = new ObservableImpl<Shortcut[]>(subscriber => {
        this.subscribers.add(subscriber);

        return () => {
            this.subscribers.delete(subscriber);
        };
    });

    private async getFromUserProfile(): Promise<Shortcut[]> {
        const record = await this.userProfileDataApi.get<Shortcut[]>(this.bucketName, 'items');
        return record?.value ?? [];
    }

    private shortcutsSnapshot: Shortcut[] = [];

    private notify() {
        this.getFromUserProfile().then(shortcuts => {
            this.shortcutsSnapshot = shortcuts;
            for (const subscription of this.subscribers) {
                subscription.next(this.shortcutsSnapshot);
            }
        });
    }
}

