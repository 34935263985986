import { genPageTheme, shapes, createUnifiedTheme, UnifiedThemeOptions, UnifiedTheme } from '@backstage/theme';
import { useTheme } from '@mui/material';
import { OnrampPaletteAdditions, OnrampTheme } from './types';

export const onrampCustomStaticPalette: OnrampPaletteAdditions = {
    devopsMetrics: {
        elite: '#460587', // non-horizon color
        high: '#28800A', // green 600
        medium: '#FFA700', // yellow 800
        low: '#DE1B1B', // red 600
        lightText: '#F0F1F2', // slate 100
        darkText: '#2A343D', // slate 900
    },
    charts: {
        neutral: '#4094E3', // blue 500
        positive: '#3AA612', // green 500
        negative: '#DE1B1B', // red 600
        hues: [
            '#DE1B1B', // red 600
            '#FFA700', // yellow 800
            '#FCEC3F', // yellow 400
            '#28800A', // green 600
            '#4094E3', // blue 500
            '#460587', // non-horizon color
            '#FCB6B9', // red 300
        ],
    },
    icons: {
        apigee: {
            primary: '#4094E3', // blue 500
            secondary: '#4094E3', // blue 500
            monochrome: '#ABAFB3', // slate 400
        },
        stackOverflow: {
            primary: '#f48024', // non-horizon color
            secondary: '#bcbbbb', // non-horizon color
            monochrome: '#565D65', // slate 700
        },
        apim: {
            primary: '#4094E3', // blue 500
            secondary: '#4094E3', // blue 500
            monochrome: '#ABAFB3', // slate 400
        },
    },
    shortcuts: {
        documentation: '#D61375', // raspberry 600
        default: '#870546', // raspberry 800
        catalog: '#28800A', // green 600
    },
};

const createOnrampTheme = (themeOptions: UnifiedThemeOptions) => {
    const theme = createUnifiedTheme(themeOptions);
    return theme;
};

export const currentTheme: UnifiedTheme = createOnrampTheme({
    palette: {
        type: 'light',
        background: {
            default: '#F5F6F7', // slate 50
        },
        status: {
            ok: '#3AA612', // green 500
            warning: '#FFA700', // yellow 800
            error: '#DE1B1B', // red 600
            running: '#1871C9', // blue 600
            pending: '#FCEC3F', // yellow 400
            aborted: '#6B7178', // slate 600
        },
        bursts: {
            fontColor: '#FFFFFF', // non-horizon color
            slackChannelText: '#DEDFE0', // slate 200
            backgroundColor: {
                default: '#3E464F', // slate 800
            },
            gradient: {
                linear: 'linear-gradient(-137deg, #6EB3F0 0%, #28800A 100%)', // green 600, blue 400
            },
        },
        primary: {
            main: '#1871C9', // blue 600
        },
        banner: {
            info: '#1871C9', // blue 600
            error: '#DE1B1B', // red 600
            text: '#FFFFFF', // non-horizon color
            link: '#001D3A', // blue 1000
        },
        border: '#DEDFE0', // slate 200
        textContrast: '#001D3A', // blue 1000
        textVerySubtle: '#DEDFE0', // slate 200
        textSubtle: '#6B7178', // slate 600
        highlight: '#FFF9C0', // yellow 100
        errorBackground: '#FFEBF7', // raspberry 100
        warningBackground: '#FFA700', // yellow 800
        infoBackground: '#F0F7FC', // blue 50
        errorText: '#BE0000', // red 700
        infoText: '#004487', // blue 800
        warningText: '#001D3A', // blue 1000
        linkHover: '#4094E3', // blue 500
        link: '#1871C9', // blue 600
        gold: '#FFD6D9', // red 200
        navigation: {
            background: '#053361', // blue 900
            indicator: '#DEDFE0', // slate 200
            color: '#ABAFB3', // slate 400
            selectedColor: '#FFFFFF', // non-horizon color
            navItem: {
                hoverBackground: '#3E464F', // slate 800
            },
            submenu: {
                background: '#3E464F', // slate 800
            },
        },
        pinSidebarButton: {
            icon: '#001D3A', // blue 1000
            background: '#C8CACC', // slate 300
        },
        tabbar: {
            indicator: '##C8CACC', // slate 300
        },
        ...onrampCustomStaticPalette,
    },
    defaultPageTheme: 'home',
    pageTheme: {
        home: genPageTheme({ colors: ['#053361', '#4094E3'], shape: shapes.wave }), // blue 900, blue 500
        documentation: genPageTheme({ colors: ['#8C9196', '#DEDFE0'], shape: shapes.wave2 }), // slate 500, slate 200
        tool: genPageTheme({ colors: ['#053361', '#4094E3'], shape: shapes.round }), // blue 900, blue 500
        service: genPageTheme({ colors: ['#053361', '#4094E3'], shape: shapes.wave }), // blue 900, blue 500
        website: genPageTheme({ colors: ['#053361', '#4094E3'], shape: shapes.wave }), // blue 900, blue 500
        library: genPageTheme({ colors: ['#053361', '#4094E3'], shape: shapes.wave }), // blue 900, blue 500
        other: genPageTheme({ colors: ['#053361', '#4094E3'], shape: shapes.wave }), // blue 900, blue 500
        app: genPageTheme({ colors: ['#053361', '#4094E3'], shape: shapes.wave }), // blue 900, blue 500
        apis: genPageTheme({ colors: ['#28800A', '#55C429'], shape: shapes.wave2 }), // green 600, green 400
    },
});

export const onrampTheme = currentTheme.getTheme('v5') as OnrampTheme;

export const useOnrampTheme = (): OnrampTheme => {
    return useTheme<OnrampTheme>();
};
