import { Step, StepLabel, Stepper } from '@mui/material';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
    gotoSpecificStep,
    selectCurrentSteps,
    selectMaxStepIndex,
} from '../../redux/slices/inProgressProvisioningRequestsSlice';
import classNames from 'classnames';
import { onrampTheme } from '../../../../theme';
import { selectCurrentStepIndex } from '../../redux/slices/inProgressProvisioningRequestsSlice/selectors';
import { createStyles } from '@mui/styles';
import { createTheme, ThemeProvider, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((_theme: Theme) =>
    createStyles({
        progressTracker: {
            backgroundColor: 'transparent',
        },
    }),
);

const theme = createTheme(
    {
        components: {
            MuiStepIcon: {
                styleOverrides: {
                    root: {
                        '&$active': {
                            color: onrampTheme.palette.navigation.background,
                        },
                        '&$completed': {
                            color: onrampTheme.palette.navigation.background,
                        },
                    },
                },
            },
            MuiStep: {
                styleOverrides: {
                    root: {
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
            },
        },
    },
    onrampTheme,
);

export function ProgressTracker() {
    const currentStepIndex = useAppSelector(selectCurrentStepIndex);
    const steps = useAppSelector(selectCurrentSteps);
    const maxStepIndex = useAppSelector(selectMaxStepIndex);
    const styles = useStyles();
    const dispatch = useAppDispatch();

    return (
        <ThemeProvider theme={theme}>
            <Stepper
                orientation="vertical"
                nonLinear
                className={classNames(styles.progressTracker, 'onboarding-progress-tracker')}
            >
                {steps.map((step, index) => (
                    <Step
                        key={index}
                        completed={currentStepIndex !== index && maxStepIndex >= index}
                        active={currentStepIndex === index}
                        expanded={false}
                        disabled={maxStepIndex < index}
                    >
                        <StepLabel
                            error={false}
                            onClick={() => {
                                if (maxStepIndex >= index) {
                                    dispatch(gotoSpecificStep(index));
                                }
                            }}
                        >
                            {step.description}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </ThemeProvider>
    );
}
