import { createApiRef } from '@backstage/core-plugin-api';
import { ClientAppConfig } from '../types/common';
import { AdxApiClient } from '../apis/AdxApiClient';
import { ADXResponse, MetadataDataResponse, QueryRecordsApprovalResponse } from '../redux/slices/adxSlices/adxRequestSlice';

export interface PostRallyCardRequest {
    requestor: string;
    contactEmail: string;
    pocName: string;
    teamName: string;
    needHelpFrom: string;
    feedback: string;
    urgent: string;
    urgentReason: string;
    neededBy: string;
    description: string;
    vp: string;
}
export interface AdxApi {
    fetchAppConfig(): Promise<ClientAppConfig>;
    fetchRegistrarQueryRecords(query_string?: string, request_type?: string, request_id?: string): Promise<ADXResponse>;
    fetchRegistrarQueryRecordsApproval(query_string?: string, request_type?: string, request_id?: string): Promise<QueryRecordsApprovalResponse>;
    fetchRegistrarQueryMetadata(queryType: string): Promise<MetadataDataResponse>;
    fetchStagrSearchTags(): Promise<ADXResponse>;
    postRallyCard(request: PostRallyCardRequest): Promise<ADXResponse>;
    postApproveRequest(action?: string, request_type?: string, request_id?: string, user_id?: string, rejected_reason?: string): Promise<ADXResponse>;
}

export class AdxClient implements AdxApi {
    private readonly adxApiClient: AdxApiClient;

    constructor(options: { adxApiClient: AdxApiClient }) {
        this.adxApiClient = options.adxApiClient;
    }

    async fetchAppConfig(): Promise<ClientAppConfig> {
        const response = await this.adxApiClient.adxFetch('/');
        return await response.json();
    }

    async fetchRegistrarQueryRecords(
        requestType?: string,
        requestId?: string,
        queryString?: string,
    ): Promise<ADXResponse> {
        let params = '?';
        const adxConfig = this.adxApiClient.getAdxConfig();
        const functionUrl = adxConfig.registrarBaseUrl;
        if (requestType && requestType !== '') {
            if (params !== '?') {
                params += '&';
            }
            params += `request_type=${requestType}`;
        }
        if (requestId && requestId !== '') {
            if (params !== '?') {
                params += '&';
            }
            params += `request_id=${requestId}`;
        }
        if (queryString && queryString !== '') {
            if (params !== '?') {
                params += '&';
            }
            params += `query_string=${queryString}`;
        }
        return await this.adxApiClient
            .adxFetch(params, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    target_uri: `${functionUrl}/api/QueryRecords`,
                },
            })
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            });
    }

    async fetchRegistrarQueryRecordsApproval(
        queryString?: string,
        requestType?: string,
        requestId?: string,
    ): Promise<QueryRecordsApprovalResponse> {
        let params = '?';
        const adxConfig = this.adxApiClient.getAdxConfig();
        const functionUrl = adxConfig.registrarBaseUrl;
        if (queryString && queryString !== '') {
            if (params !== '?') {
                params += '&';
            }
            params += `query_string=${queryString}`;
        }
        if (requestType && requestType !== '') {
            if (params !== '?') {
                params += '&';
            }
            params += `request_type=${requestType}`;
        }
        if (requestId && requestId !== '') {
            if (params !== '?') {
                params += '&';
            }
            params += `request_id=${requestId}`;
        }
        return await this.adxApiClient
            .adxFetch(params, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    target_uri: `${functionUrl}/api/QueryRecords`,
                },
            })
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            });
    }

    async fetchRegistrarQueryMetadata(queryType: string): Promise<MetadataDataResponse> {
        const adxConfig = this.adxApiClient.getAdxConfig();
        const functionUrl = adxConfig.registrarBaseUrl;
        return await this.adxApiClient
            .adxFetch(`?metadata=tags&type=${queryType}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    target_uri: `${functionUrl}/api/QueryMetadata`,
                },
            })
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            });
    }

    async fetchStagrSearchTags(): Promise<ADXResponse> {
        const adxConfig = this.adxApiClient.getAdxConfig();
        const functionUrl = adxConfig.stagrBaseUrl;
        return await this.adxApiClient
            .adxFetch('', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    target_uri: `${functionUrl}/api/SearchTags`,
                },
            })
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            });
    }

    async postRallyCard(request: PostRallyCardRequest): Promise<ADXResponse> {
        const adxConfig = this.adxApiClient.getAdxConfig();
        const functionUrl = adxConfig.registrarBaseUrl;
        return await this.adxApiClient
            .adxFetch('', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    target_uri: `${functionUrl}/api/RallyCard`,
                },
                body: JSON.stringify(request),
            })
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            });
    }

    async postApproveRequest(
        action?: string,
        request_type?: string,
        request_id?: string,
        user_id?: string,
        rejected_reason?: string): Promise<ADXResponse> {
        let params = '?';
        const adxConfig = this.adxApiClient.getAdxConfig();
        const functionUrl = adxConfig.registrarBaseUrl;
        if (action && action !== '') {
            if (params !== '?') {
                params += '&';
            }
            params += `action=${action}`;
        }
        if (request_type && request_type !== '') {
            if (params !== '?') {
                params += '&';
            }
            params += `request_type=${request_type}`;
        }
        if (request_id && request_id !== '') {
            if (params !== '?') {
                params += '&';
            }
            params += `request_id=${request_id}`;
        }
        if (rejected_reason && rejected_reason !== '') {
            if (params !== '?') {
                params += '&';
            }
            params += `rejected_reason=${rejected_reason}`;
        }
        return await this.adxApiClient
            .adxFetch(params, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    target_uri: `${functionUrl}/api/ApproveRequest`,
                },
                body: {
                    "ExternalUserContext": {
                        "UserId": user_id
                    }
                }
            })
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            });
    }
}

export const adxApiRef = createApiRef<AdxApi>({
    id: 'azuredeveloperexperience',
});
